import React from "react"
import Layout from "../components/layout"
import NavBar from "../components/navBar/navBar"
import Seo from "../components/seo"

function AlbumsPage() {
    return (
      <Layout>
        <Seo title="Albums" />
        <NavBar />
        <p>
            Coming soon....
        </p>
      </Layout>
    )
  }
  
  export default AlbumsPage